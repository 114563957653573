/* eslint-disable no-console, no-undef, no-case-declarations, camelcase */
import _ from 'lodash';

import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  dailyTip: null,
  dailyTipError: null,
  isLoading: false,
  motivationalQuote: null,
  motivationalQuoteError: null,
  networkError: null,
  activityOverview: null,
  activityOverviewError: null,
  devices: [],
  devicesError: null,
  fitbitToken: null,
  withingsToken: null,
  stravaToken: null,
  garminToken: null,
  list: null,
  listError: null,
  reward: null,
  rewardError: null,
  monthlyRewardPoint: null,
  activeChallenge: null,
  upComingChallenge: null,
  dailyActivityOverview: null,
  activityLoading: false,
  monthlyActivityOverview: null,
  fitnessDetails: [],
  socialDetails: [],
  wellnessDetails: [],
  educationDetails: [],
  bonusWellness: [],
  bonusSocial: [],
  userHistoryPoints: [],
  bonusFitness: [],
  bonusEducation: [],
  companyBrandingDetails:{},
  companyFeatures: [],
  companyRewards: [],
  companyRewardsDocuments: {},
  companyRewardsBarValues:[],
  monthlyAdminRewardPoint:null,
  rewardAdminError:null,
  rewardData: [],
  userRewardHistoryDetails: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

  case actionTypes.GET_ACTIVE_CHALLENGE:
    return {
      ...state,
      activeChallenge: action.value
    }

  case actionTypes.GET_UPCOMING_CHALLENGE:
    return {
      ...state,
      upComingChallenge: action.value
    }
  case actionTypes.GET_MONTHLY_REWARD_POINTS:
    return {
      ...state,
      monthlyRewardPoint: action.reward
    }

  case actionTypes.WELLNESS_DASHBOARD_LOADING:
    return {
      ...state,
      isLoading: true
    };

  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      networkError: action.networkError,
      isLoading: false
    };

  case actionTypes.GET_ACTIVITY_OVERVIEW:
    return {
      ...state,
      activityOverview: action.activityOverview,
      isLoading: false
    };

  case actionTypes.FAILED_GET_ACTIVITY_OVERVIEW:
    return {
      ...state,
      activityOverviewError: action.activityOverviewError,
      isLoading: false
    };

  case actionTypes.GET_LIST_OF_DEVICES:
    return {
      ...state,
      devices: action.devices
    };

  case actionTypes.FAILED_GET_LIST_OF_DEVICES:
    return {
      ...state,
      devicesError: action.devicesError
    };

  case actionTypes.UPDATE_DEVICES:
    let updatedData = [];
    if(!_.isNull(state.devices) && !_.isUndefined(state.devices)) {
      updatedData = [...state.devices];
      const selectedDevice = state.devices.filter((device) => device.name === action.name);
      const index = state.devices.findIndex((device) => device.name === action.name);
      if(index > -1) {
        selectedDevice[0].is_connected = action.value;
        updatedData[index] = selectedDevice[0];
      }
    }
    return {
      ...state,
      devices: updatedData
    };

  case actionTypes.SAVE_FITBIT_TOKEN:
    return {
      ...state,
      fitbitToken: action.fitbitToken
    };

  case actionTypes.SAVE_GARMIN_TOKEN:
    return {
      ...state,
      garminToken: action.garminToken
    };

  case actionTypes.SAVE_STRAVA_TOKEN:
    return {
      ...state,
      stravaToken: action.stravaToken
    };

  case actionTypes.SAVE_WITHINGS_TOKEN:
    return {
      ...state,
      withingsToken: action.withingsToken
    };

  case actionTypes.GET_LIST_OF_THINGS:
    return {
      ...state,
      list: action.list
    };

  case actionTypes.FAILED_GET_LIST_OF_THINGS:
    return {
      ...state,
      listError: action.listError
    };

  case actionTypes.GET_REWARD_POINTS:
    return {
      ...state,
      reward: action.reward
    };

  case actionTypes.GET_REWARD_HISTORY_POINTS:
    let newData = [...state.rewardData]
    newData.push(action.rewardData)
    // console.error("@@@@@ new Data", newData, "state",state.rewardData, "action",action.rewardData)
    return {
      ...state,
      rewardData: newData
    };  

  case actionTypes.FAILED_GET_REWARD_POINTS:
    return {
      ...state,
      rewardError: action.rewardError
    };
  case actionTypes.GET_DAILY_ACTIVITY_OVERVIEW:
    return {
      ...state,
      dailyActivityOverview: action.dailyActivityOverview,
      activityLoading: false
    };
  case actionTypes.DAILY_ACTIVITY_LOADING:
    return {
      ...state,
      activityLoading: true
    };
  case actionTypes.GET_MONTHLY_ACTIVITY_OVERVIEW:
    return {
      ...state,
      monthlyActivityOverview: action.monthlyActivityOverview,
      activityLoading: false
    };
  case actionTypes.GET_USER_REWARDS_DETAILS:
    return{
      ...state,
      fitnessDetails: action.userRewardsDetails['fitness'],
      socialDetails: action.userRewardsDetails['social'],
      wellnessDetails: action.userRewardsDetails['wellness'],
      educationDetails: action.userRewardsDetails['education'],
      bonusWellness: action.userRewardsDetails['bonus_wellness'],
      bonusSocial: action.userRewardsDetails['bonus_social'],
      bonusFitness: action.userRewardsDetails['bonus_fitness'],
      bonusEducation: action.userRewardsDetails['bonus_education']
    }


  case actionTypes.GET_USER_REWARD_HISTORY_DETAILS:
    // let temp = Object.assign(state.userRewardHistoryDetails, action.userRewardHistoryDetails)
    let temp = [...state.userRewardHistoryDetails]
    temp.push(action.userRewardHistoryDetails)
    return{
      ...state, userRewardHistoryDetails: temp
    }   

  case actionTypes.GET_USER_HISTORY_POINTS:
    return{
      ...state,
      userHistoryPoints: action.userHistoryPoints
    }
  case actionTypes.GET_COMPANY_BRANDING_DETAILS:
    return{
      ...state,
      companyBrandingDetails: action.companyBrandingDetails
    }
  case actionTypes.GET_COMPANY_FEATURES:
    return{
      ...state,
      companyFeatures: action.companyFeatures
    }

  case actionTypes.GET_COMPANY_REWARDS:
    return {
      ...state,
      companyRewards: action.companyRewards
    }

  case actionTypes.GET_COMPANY_DOCUMENTS:
    return {
      ...state,
      companyRewardsDocuments: action.companyRewardsDocuments
    }

  case actionTypes.CLEAR_COMPANY_DOCUMENTS:
    return {
      ...state,
      companyRewardsDocuments: {}
    }
  case actionTypes.COMPANY_REWARDS_TIER:
    return{
      ...state,
      companyRewardsBarValues: action.companyRewardsBarValues
    }
  case actionTypes.GET_ADMIN_MONTHLY_REWARD_POINTS:
    return {
      ...state,
      monthlyAdminRewardPoint: action.reward
    }
  case actionTypes.FAILED_GET_ADMIN_REWARD_POINTS:
    return {
      ...state,
      rewardAdminError: action.rewardAdminError
    };  
  default: break;
  }
  return state;
};

export default reducer;
