/* eslint-disable no-console,no-undef,camelcase,no-case-declarations */
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  Medical: [],
  Lifestyle: [],
  Nutrition: [],
  Fitness: [],
  Stress: [],
  Workplace: [],
  networkError: null,
  hraQuestionsAnswersData:null
};

const getUpdatedData = (prevData, question, answer, isMultipleAnswers, type, count) => {
  const dataIndex = prevData.findIndex((data) => data.question === question);
  if(dataIndex > -1) {
    if(isMultipleAnswers) {
      if(prevData[dataIndex].answer.find((obj)=>obj==answer)) {
        prevData[dataIndex].answer =prevData[dataIndex].answer.filter((obj)=>obj!=answer)
      } else {
        prevData[dataIndex].answer.push(answer)
      }
    } else {
      prevData[dataIndex].answer=[answer]
    }
    prevData[dataIndex].count = count;
  } else {
    let object = {
      question: question,
      type: type,
      answer: [answer],
      count: count
    };
    prevData.push(object);
  }
  return prevData;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

  case actionTypes.LOADING:
    return {
      ...state,
      isLoading: true
    };

  case actionTypes.SAVE_MEDICAL_DATA: {
    return {
      ...state,
      Medical: getUpdatedData(state.Medical.slice(), action.question, action.answer, action.multipleAnswers, 'medical', action.count)
    };
  }

  case actionTypes.GET_MEDICAL_DATA:
    return {
      ...state,
      medicalData: state.Medical
    };

  case actionTypes.SAVE_LIFESTYLE_DATA: {
    return {
      ...state,
      Lifestyle: getUpdatedData(state.Lifestyle.slice(), action.question, action.answer, action.multipleAnswers, 'lifestyle', action.count)
    };
  }

  case actionTypes.GET_LIFESTYLE_DATA:
    return {
      ...state,
      lifestyleData: state.Lifestyle
    };

  case actionTypes.SAVE_NUTRITION_DATA: {
    return {
      ...state,
      Nutrition: getUpdatedData(state.Nutrition.slice(), action.question, action.answer, action.multipleAnswers, 'nutrition', action.count)
    };
  }

  case actionTypes.GET_NUTRITION_DATA:
    return {
      ...state,
      nutritionData: state.Nutrition
    };

  case actionTypes.SAVE_FITNESS_DATA: {
    return {
      ...state,
      Fitness: getUpdatedData(state.Fitness.slice(), action.question, action.answer, action.multipleAnswers, 'fitness', action.count)
    };
  }

  case actionTypes.GET_FITNESS_DATA:
    return {
      ...state,
      fitnessData: state.Fitness
    };

  case actionTypes.SAVE_STRESS_DATA: {
    return {
      ...state,
      Stress: getUpdatedData(state.Stress.slice(), action.question, action.answer, action.multipleAnswers, 'stress', action.count)
    };
  }

  case actionTypes.GET_STRESS_DATA:
    return {
      ...state,
      stressData: state.Stress
    };

  case actionTypes.SAVE_WORKPLACE_DATA: {
    return {
      ...state,
      Workplace: getUpdatedData(state.Workplace.slice(), action.question, action.answer, action.multipleAnswers, 'workplace', action.count)
    };
  }

  case actionTypes.GET_WORKPLACE_DATA:
    return {
      ...state,
      workplaceData: state.Workplace
    };

  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      networkError: action.networkError
    };

  case actionTypes.CLEAR_WELLBEING_ASSESSMENT:
    return {
      ...state,
      Medical: [],
      Lifestyle: [],
      Nutrition: [],
      Fitness: [],
      Stress: [],
      Workplace: []
    };
  case actionTypes.HRA_QUESTIONS_ANSWERS:
    return {
      ...state,
      hraQuestionsAnswersData: action.hraQuestionsAnswersData
    };    

  default: break;
  }

  return state;
};

export default reducer;