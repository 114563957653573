/* eslint-disable no-console, no-undef, no-case-declarations, camelcase */
import * as actionTypes from '../../actions/actionTypes';
const initialState = {
  assesmentDetails: [],
  healthWellbeingCardData:[],
  wellnessWins:[],
  addressableHealthRisk:[],
  wellbeingCategoriesData:[],
  WbaQuestionData:[],
  healthImprovementOpportunities:[]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.ASSESMENT_DETAILS:
    return{
      ...state,
      assesmentDetails: action.assesmentDetails
    };
  case actionTypes.HEALTH_WELLBEING_CARD:
    return{
      ...state,
      healthWellbeingCardData: action.healthWellbeingCardData
    };
  case actionTypes.TOP_WELLNESS_WINS:
    return{
      ...state,
      wellnessWins: action.wellnessWins
    };
  case actionTypes.TOP_ADDRESSABLE_HEALTH_RISK:
    return{
      ...state,
      addressableHealthRisk: action.addressableHealthRisk
    };
  case actionTypes.GET_WELLBEING_CATEGORIES:
    return{
      ...state,
      wellbeingCategoriesData: action.wellbeingCategoriesData
    };
  case actionTypes.GET_WBA_QUESTION_BY_CATEGORYID:
    return{
      ...state,
      WbaQuestionData: action.WbaQuestionData
    };
  case actionTypes.TOP_HEALTH_IMPROVEMENT:
    return{
      ...state,
      healthImprovementOpportunities: action.healthImprovementOpportunities
    };
  default:
    break;
  }
  return state;
};

export default reducer;